import React, { useEffect, useState, useRef } from 'react'

import {
  PROJECT_NAME,
  DJANGO,
  IN_DEPLOYMENT,
  desk,
  mob,
  userAgent,
  userAgentType,
  user_id,
} from '../components/ROUTERJS'
import { src } from '../.core.js'
import { fun } from '../.fun.js'

import Bug from './svg/Bug.js'
import Heart from './svg/Heart.js'

import ArrowBack from '../svg/ArrowBack.js'
import ArrowLeft from '../svg/ArrowLeft'
import Chevron from '../svg/Chevron.js'
import Spinner from '../svg/Spinner.js'
import Tick from '../svg/Tick.js'
import Trash from '../svg/Trash.js'
import X from '../svg/X.js'

export default function BetaPortal({
  props: { betaPortal, usn, _beta, _betaPortal },
}) {
  // const aboutRefs = useRef([])
  const reportUARef = useRef()
  const bugRef = useRef()
  const feedbackRef = useRef()

  const [loved, _loved] = useState()

  // 🐞
  // user bugs profile
  const [bugsTotal, setBugsTotal] = useState(0)
  const [bugsPatched, setBugsPatched] = useState(0)
  const [patchNotes, setPatchNotes] = useState(false)
  // ...
  const [reportBugs, _reportBugs] = useState(false)
  const [submittedBugs, setSubmittedBugs] = useState([])
  const [caughtBugs, setCaughtBugs] = useState([])
  const [writeBug, setWriteBug] = useState(false)
  const [editBug, setEditBug] = useState(null)

  const [feedback, _feedback] = useState(false)

  const [submit, setSubmit] = useState(false)

  // backend
  const [waiting, setWaiting] = useState([])
  const [received, setReceived] = useState([])

  const [init, _init] = useState(true)

  const log1 = (a, b) => fun().log1(a, b)
  const log2 = (a, b) => fun().log2(a, b)

  const handleNav = (
    <>
      {patchNotes && (
        <div
          id="bArrowBack"
          className="b-ui-element noselect"
          style={{ display: patchNotes ? 'flex' : 'none' }}
        >
          <ArrowBack type="sharp" pathway={() => setPatchNotes(false)} />
        </div>
      )}
      <div
        id="bX"
        className="b-ui-element noselect"
        style={{ color: patchNotes ? 'black' : 'white' }}
        onClick={() => unset() + _betaPortal(false)}
      >
        <X type="sharp" />
      </div>
    </>
  )

  const handleHeart = () => {
    backend('loved')
    _loved(true)
  }

  function handleTextareaBugs(e) {
    if (e.target.value.length > 0) {
      setWriteBug(true)
    } else {
      setWriteBug(false)
    }
  }

  function handleTextareaFeedback(e) {
    if (e.target.value.length > 0) {
      setSubmit(true)
      if (received.includes('feedback')) {
        setReceived(received.filter((elm) => elm !== 'feedback'))
      }
    } else {
      setSubmit(false)
    }
  }

  function backend(type, value = null) {
    const then = () => {
      setWaiting(waiting.slice(1))
      switch (type) {
        case 'bugs':
          setBugsTotal(bugsTotal + caughtBugs.length)
          setSubmittedBugs([...submittedBugs, ...caughtBugs])
          setCaughtBugs([])
          _reportBugs(false)
        case 'feedback':
          setSubmit(false)
          feedbackRef.current.value = ''
      }
    }
    // < catch
    // ## repeat firing -> clicking outside of browser triggers onBlur but doesn't unfocus object.
    // ## everytime you return to the browser it's ready to blur again.
    if (type === 'userAgent' && value === reportedUA) {
      // reportUARef.current.blur()
      return
    }
    // catch />
    // < prehandling
    switch (type) {
      case 'loved':
        value = true
        break
      case 'bugs':
        value = caughtBugs
      case 'feedback':
        value = feedbackRef.current.value
        if (value.length === 0) return
    }
    // prehandling />
    if (DJANGO) {
      fetch('__beta/submit/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userID: user_id,
          usn: usn,
          userAgent: userAgent,
          type: type,
          value: value,
        }),
      })
        .then((response) => {
          console.log(response)
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          then()
          // return response.json()
        })
        // .then((data) => {
        //    ...
        // })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error)
        })
    } else {
      if (type === 1 || type === 2 || type === 3) {
        console.log(TypesDict[type].name, ' = ', value)
      } else {
        log1(type, value)
      }
      // simulated response time
      setTimeout(() => {
        then()
      }, 1000)
    }
  }

  function bug() {
    return {
      add: () => {
        const caught_bug = bugRef.current.value
        if (caught_bug.length === 0) return
        setCaughtBugs([...caughtBugs, caught_bug])
        bugRef.current.value = ''
        setWriteBug(false)
      },
      del: (i) => {
        const copyCaughtBugs = [...caughtBugs]
        copyCaughtBugs.splice(i, 1)
        setCaughtBugs(copyCaughtBugs)
      },
    }
  }

  function edit_bug(index, fun = null) {
    if (fun === 'save') {
      const copyCaughtBugs = [...caughtBugs]
      copyCaughtBugs.splice(index, 1, bugRef.current.value)
      setCaughtBugs(copyCaughtBugs)
      bugRef.current.value = ''
      setEditBug(null)
    } else {
      setEditBug(index)
      bugRef.current.value = caughtBugs[index]
    }
  }

  function unset() {
    // setReportUA(false)
    _reportBugs(false)
    _feedback(true)
    setReceived([])
  }

  useEffect(() => {
    if (loved) {
      setTimeout(() => {
        _loved(false)
      }, 2000)
    }
  }, [loved])

  // 🐞
  // useEffect(() => {
  //   if (IN_DEPLOYMENT) {
  //     console.log('... fetching __beta/get_rating/')
  //     fetch(`__beta/get_rating?tester=${user_id}`)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok')
  //         }
  //         return response.json()
  //       })
  //       .then((data) => {
  //         if (data.rating) {
  //           setBugsTotal(beta_user.bugs.total)
  //           setBugsPatched(beta_user.bugs.patched)
  //           _init(true)
  //         } else {
  //           _init(true)
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('There was a problem with the fetch operation:', error)
  //       })
  //   } else {
  //     const beta_user = beta_test_json[user_id]
  //     setBugsTotal(beta_user.bugs.total)
  //     setBugsPatched(beta_user.bugs.patched)
  //     _init(true)
  //   }
  // }, [])

  useEffect(() => {
    if (feedback) {
      feedbackRef.current.focus()
    }
    if (reportBugs) {
      bugRef.current.focus()
    }
  }, [feedback, reportBugs])

  useEffect(() => {
    return () => {
      _feedback(false)
      _reportBugs(false)
    }
  }, [betaPortal])

  return !init ? null : (
    <div
      id="BetaPortal"
      className={betaPortal && 'open'}
      style={{
        height: mob ? window.innerHeight + 'px' : '100vh',
        // background: reportBugs ? 'black' : '#1e2a43',
      }}
    >
      {loved && (
        <p id="thank-you" className="x">
          Thank-You!
        </p>
      )}
      <div className="wrapper">
        {handleNav}
        {patchNotes ? (
          <div id="PatchNotes">
            <p>Your bug(s) haven't been patched yet. Check back later!</p>
          </div>
        ) : null}
        <div
          className="content"
          style={{ display: patchNotes ? 'none' : 'flex' }}
        >
          <p id="project_id">{PROJECT_NAME.upperCase}</p>
          {feedback || reportBugs ? (
            <>
              <ArrowBack
                pathway={() => _feedback(false) + _reportBugs(false)}
              />
              {/* Report Bugs */}
              <div
                id="Report_Bugs"
                className="section-body posrel"
                style={{ display: reportBugs ? 'block' : 'none' }}
              >
                <div id="-report-bugs">
                  <p
                    className="report-bugs nomarj b-color"
                    style={{ display: caughtBugs.length > 0 ? 'flex' : 'none' }}
                    onClick={() => backend('bugs')}
                  >
                    Report ({caughtBugs.length})
                  </p>
                  <div className="response --left">
                    {waiting.includes('bugs') ? <Spinner /> : null}
                  </div>
                </div>
                {caughtBugs.length > 0 ? (
                  <div id="caught-bugs">
                    {caughtBugs.map((b, index) => (
                      <div className="-caught-bug">
                        <div
                          className="-f-a caught-bug hover-low"
                          style={{ color: fun().randCol() }}
                          onClick={() => edit_bug(index)}
                        >
                          <Bug strokeWidth={1.5} />
                          <p>{b}</p>
                        </div>
                        <div className="-trash-">
                          <div
                            className="-trash ps-top-mini"
                            onClick={() => bug().del(index)}
                          >
                            <Trash />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                <textarea
                  ref={bugRef}
                  name=""
                  id=""
                  placeholder="What bugs(s) have you encountered?"
                  onChange={(e) => handleTextareaBugs(e)}
                ></textarea>
                <p
                  className="submit"
                  onClick={() =>
                    // editBug !== null ? edit_bug(editBug, 'save') : bug().add()
                    backend('bugs')
                  }
                >
                  {/* {editBug !== null ? 'Save edit' : 'Add bug'} */}
                  Report Bugs
                </p>
              </div>
              {/* Feedback */}
              <div
                id="Feedback_Suggestions"
                className="section-body"
                style={{ display: feedback ? 'block' : 'none' }}
              >
                <textarea
                  id="feedback_suggestions_textarea"
                  ref={feedbackRef}
                  placeholder="..."
                  onChange={(e) => handleTextareaFeedback(e)}
                ></textarea>
                <p className="submit" onClick={() => backend('feedback')}>
                  Submit Feedback
                </p>
              </div>
            </>
          ) : (
            <div id="love" className="--f-a xy">
              <p>Like the site?</p>
              <div
                id="heart"
                className={loved && 'loved'}
                onClick={() => handleHeart()}
              >
                <Heart />
              </div>
              <p>Show us some love</p>
            </div>
          )}
          {/* User bugs history */}
          {bugsTotal > 0 && (
            <div className="bugs-meta">
              <p className="mb0">You've caught: {bugsTotal} bugs</p>
              <p className="tiny inline">
                - of which {bugsPatched} have been patched 👍
              </p>
              <p
                id="patch-notes"
                className="tiny inline rlink"
                onClick={() => setPatchNotes(true)}
              >
                {'  '}Patch Notes
              </p>
            </div>
          )}
          {/* <div className="x feedback-handles">
            <p
              className="rlink"
              onClick={() => _reportBugs(false) + _feedback(true)}
            >
              Got a suggestion?
            </p>
            <div
              id="handleBugs"
              onClick={() => _feedback(false) + _reportBugs(true)}
            >
              <p className="rlink" style={{ color: '#ff7c7c' }}>
                Found a bug?
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
