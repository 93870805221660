import React, { useEffect, useState } from 'react'

import { fun } from '../.fun.js'
import { src } from '../.core.js'

import {
  DJANGO,
  IN_DEPLOYMENT,
  desk,
  mob,
  user_id,
} from '../components/ROUTERJS'

import beta_json from './data/beta.json'

// import Desktop from './svg/Desktop.js'
// import Mobile from './svg/Mobile.js'
import ArrowBack from '../svg/ArrowBack.js'
import X from '../svg/X.js'
import I from './svg/I.js'

const dev_statuses = {
  'pre-alpha':
    'An initial work in progress, features are being built and may not be functional. The platform is untested across different devices and environments.',
  alpha:
    'A pre-release version, features are mostly functional but not stable. Internal testing ongoing.',
  beta: 'An early release, in testing with select users.',
  'release candidate':
    'Almost ready for full release, minor tweaks and final testing.',
  stable: 'Official release, fully tested and ready for general use.',
  maintenance: 'Minor updates and bug fixes only, no new features planned.',
}

export default function BetaLanding({
  props: { beta, user, _beta, _betaPortal },
}) {
  const [about, setAbout] = useState(false)
  const [previousAnnouncement, setPreviousAnnouncement] = useState(false)
  const [FVH, setFVH] = useState(false)

  const [userPathway, setUserPathway] = useState([])

  const handle_nav = () => {
    if (beta === 'BetaPortal' || userPathway.length) {
      return (
        <div id="bArrowBack" className="b-ui-element">
          <ArrowBack type="sharp" pathway={pathway} />
        </div>
      )
    } else {
      return (
        <div
          id="bX"
          className="b-ui-element noselect"
          onClick={() => _beta(false)}
        >
          <X type="sharp" />
        </div>
      )
    }
  }

  function get_FVH() {
    return (
      <div id="FVH">
        {Object.entries(beta_json.FVH)
          .reverse()
          .map(([key, value]) => (
            <>
              <p className="version">
                {key}
                {value.date && (
                  <span>
                    {' - '}
                    {value.date.split('T')[0]}
                  </span>
                )}
              </p>
              {value.log.map((e) => (
                <p>{e}</p>
              ))}
            </>
          ))}
      </div>
    )
  }

  function pathway() {
    if (userPathway.length > 0) {
      const lastElm = userPathway.pop()
      switch (lastElm) {
        case 'FVH':
          setFVH(false)
          break
        case 'about':
          setAbout(false)
          break
      }
    } else {
      if (beta === 'BetaPortal') {
        _beta(false)
        _betaPortal(true)
      }
    }
  }

  useEffect(() => {
    document.getElementById('BetaLanding').scroll(0, 0)
    if (FVH) {
      setUserPathway([...userPathway, 'FVH'])
    }
  }, [FVH])

  useEffect(() => {
    document.body.classList.add('noscroll')

    return () => {
      document.body.classList.remove('noscroll')
    }
  }, [])

  return (
    <div
      id="BetaLanding"
      className={desk ? '-f-j' : ''}
      style={{
        height: desk ? '100vh' : window.innerHeight + 'px',
      }}
    >
      {/* <div id="x"></div> */}
      <div className="content">
        <>
          {handle_nav()}
          {userPathway.length === 0 ? (
            <div id="-About" onClick={() => alert('Do challenges in a squad.')}>
              <I />
            </div>
          ) : null}
        </>
        {FVH && get_FVH()}
        <div
          className="--f-ja -project-id"
          style={{ display: FVH ? 'none' : 'flex' }}
        >
          <p id="project_id">RHENIUM</p>
          <div className="meta">
            <p
              // style={{ color: '#a1cfa1' }}
              onClick={() => alert(dev_statuses[beta_json.status])}
            >
              {beta_json.status}
            </p>
            <p
              // style={{ color: '#fe846e' }}
              onClick={() => setFVH(true)}
            >
              {beta_json.version}
            </p>
            <p
              // style={{ color: '#7688be' }}
              onClick={() => alert('Last deployed.')}
            >
              {fun().niceDate(beta_json.last_deployed, 'Dmy')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
