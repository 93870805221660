import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { handleKV } from '../actions'

import { NA, src } from '../.core.js'
import { fun } from '../.fun.js'

import { DJANGO, user_id } from '../components/ROUTERJS.js'

import dev_json from '../dev/data/dev.json'

import ArrowLeft from '../svg/ArrowLeft.js'
import _battery_ from '../svg/Battery.js'
import _fading_ from '../svg/Fading.js'
import _join_ from '../svg/Join.js'
import _msg_ from '../svg/Msg.js'
import _pickUp_ from '../svg/PickUp.js'
import _squadKO_ from '../svg/SquadKO.js'
import X from '../svg/X.js'

const { yellow } = fun().logMethods

export default function SquadActivity({
  props: {
    __MY_SQUAD__,
    __USERS__,
    initSquadActivity,
    loading,
    _location,
    setTriggerID,
    handleStatus,
  },
}) {
  const dispatch = useDispatch()

  const foo = useRef()
  const fooPlaceholder = useRef()

  const [charged, _charged] = useState([])
  // const [help, _help] = useState([])
  const [resigned, _resigned] = useState([])

  // const [caught_on, _caught_on] = useState([])
  // const [picked_up, _picked_up] = useState([])

  const [focusHelp, _focusHelp] = useState([])
  const [msgSent, _msgSent] = useState(false)

  const [hovered, _hovered] = useState(null)

  // const [initializer, _initializer] = useState(0)

  const len_up = Object.keys(__MY_SQUAD__.current.help).length
  const len_down = Object.keys(__MY_SQUAD__.current.help).length

  const pick_me_up = (event_id, option, applied) => (
    <div
      className={
        'pick-up ico-interactive ui-handle ps-top ' +
        (applied ? 'picked-up' : '')
      }
      onClick={
        applied
          ? () => {
              handleStatus('unapply', option, event_id)
              // _picked_up([...picked_up, event_id])
            }
          : () => {
              handleStatus('apply', option, event_id)
              // _picked_up([...picked_up, event_id])
            }
      }
    >
      <_pickUp_ />
    </div>
  )

  function activity_up() {
    const delete_activity = (option, event_id) => (
      <div
        className="delete ico-interactive ui-handle ps-top"
        onClick={() => handleStatus('delete', option, event_id)}
      >
        <X />
      </div>
    )
    const activity_item = (evID, evObj) => {
      const applied = evObj.users_arr.includes(user_id)
      const users_arr = [evObj.user].concat(evObj.users_arr)
      const lenArr = users_arr.length
      return (
        <div className="-f-a">
          <div className="-f- users-arr">
            {users_arr.map((chained_user_id, idx) => {
              const chained_user = __USERS__.current[chained_user_id]
              return (
                <div
                  className="-user"
                  style={{
                    transform: `translateX(${-33 * idx}%)`,
                    zIndex: lenArr - idx,
                  }}
                >
                  <img
                    className="usr-border"
                    src={src(chained_user.img, 'compressed')}
                    alt=""
                    onClick={() => dispatch(handleKV('FOCUS', chained_user_id))}
                  />
                  <p className="username x">{chained_user.usn}</p>
                  {idx === lenArr - 1 && (
                    <p className="indicator y">
                      <span className="blip">{'• '}</span>
                      {evObj.mood}
                    </p>
                  )}
                </div>
              )
            })}
          </div>
          <div className="tools">
            {evObj.user === user_id ? (
              delete_activity('charged', evID)
            ) : (
              <div
                className={
                  'join ico-interactive ui-handle ps-top' +
                  (applied ? ' joined' : '')
                }
                onClick={
                  applied
                    ? () => {
                        handleStatus('unapply', 'charged', evID)
                        // _caught_on(caught_on.filter((id) => id !== evID))
                      }
                    : () => {
                        handleStatus('apply', 'charged', evID)
                        // _caught_on([...caught_on, evID])
                      }
                }
              >
                {applied ? <_squadKO_ /> : <_join_ />}
              </div>
            )}
          </div>
        </div>
      )
    }
    const charged = Object.entries(__MY_SQUAD__.current.charged)
      .reverse()
      .map(([id, obj]) => {
        return activity_item(id, obj)
      })
    return charged
  }

  function activity_down() {
    const help_item = (evID, evObj, idx) => {
      const evUser = __USERS__.current[evObj.user]
      const isFocused = focusHelp[0] == evID
      const anotherIsFocused = focusHelp.length > 0 && !isFocused
      const isPickedUp = evObj.users_arr.includes(user_id)
      return (
        <div
          className={
            '-user' +
            (isFocused
              ? ' focused'
              : anotherIsFocused
              ? ' anotherIsFocused'
              : '')
          }
          style={{
            transform: `translateX(calc(${idx} * -33%))`,
            zIndex: `${len_down - idx}`,
          }}
        >
          <img
            className={
              'usr-border' +
              (isFocused ? ' active' : '') +
              (isPickedUp ? ' isPickedUp' : '')
            }
            src={src(evUser.img, 'compressed')}
            alt=""
            onMouseEnter={() => _hovered(evID)}
            onMouseLeave={() => _hovered(null)}
            onClick={() =>
              isFocused
                ? dispatch(handleKV('FOCUS', evObj.user))
                : _focusHelp([evID, evUser.usn])
            }
          />
          <p
            className="indicator x"
            style={{
              display:
                hovered === evID || (!hovered && isFocused) ? 'block' : 'none',
            }}
          >
            <span className="blip">•</span> {evObj.mood}
          </p>
          <p
            className="username x"
            onClick={() => dispatch(handleKV('FOCUS', evObj.user))}
          >
            {evUser.usn}
          </p>
        </div>
      )
    }
    const help = Object.entries(__MY_SQUAD__.current.help)
      .reverse()
      .map(([id, obj], idx) => {
        return help_item(id, obj, idx)
      })
    return help
  }

  function get_resigned() {
    const resign_item = (evID, evObj) => {
      const evUser = __USERS__.current[evObj.user]
      const applied = evObj.users_arr.includes(user_id)
      return (
        <div id={`X-${evID}`} className="resign-item with-background">
          <div className="-f-a -resigned">
            <div className="-f-a -user-resigned">
              <img
                className="usr-border"
                src={src(evUser.img, 'compressed')}
                alt=""
                onClick={() => dispatch(handleKV('FOCUS', evObj.user))}
              />
              <p
                className="username inline"
                onClick={() => dispatch(handleKV('FOCUS', evObj.user))}
              >
                {evUser.usn}
              </p>
            </div>
            <div className="tools">
              <div
                className="msg-in ico-interactive ui-handle ps-top"
                onClick={() =>
                  document.getElementById(`X-${evID}`).classList.toggle('open')
                }
              >
                <_msg_ arg="incoming" />
              </div>
              {pick_me_up(evID, 'resigned', applied)}
            </div>
          </div>
          <div className="-msg">
            <p className="msg">{evObj.msg}</p>
            <div
              className="-X click-me"
              onClick={() =>
                document.getElementById(`X-${evID}`).classList.toggle('open')
              }
            >
              <X />
            </div>
          </div>
        </div>
      )
    }
    const resigned = Object.entries(__MY_SQUAD__.current.resigned)
      .reverse()
      .map(([id, obj]) => {
        return resign_item(id, obj)
      })
    _resigned(resigned)
  }

  useEffect(() => {
    // activity_up()
    // activity_down()
    get_resigned()
  }, [initSquadActivity])

  useEffect(() => {
    _msgSent(false)
  }, [focusHelp])

  return (
    <div
      id="squad_activity"
      className="sticky"
      style={{ visibility: loading ? 'hidden' : 'visible' }}
    >
      {len_up > 0 && (
        <div id="-charged-" className="squad-activity-section">
          <div className="-f-">
            <div className="header">
              <_battery_ cells={4} animate={true} />
            </div>
            <div className="up-arr">{activity_up()}</div>
          </div>
        </div>
      )}
      {len_down > 0 && (
        <div id="-help-" className="squad-activity-section">
          <div className="-f-a">
            <div className="-f-a header">
              <_battery_ cells={1} animate={true} />
              {/* <p className="inline">Fading</p> */}
            </div>
            <div className="-f- users-arr">{activity_down()}</div>
          </div>
          {focusHelp.length > 0 && (
            <div className="-f-a help-dialogue-box">
              {msgSent && (
                <p id="msg-sent" className="xy">
                  Message Sent.
                </p>
              )}
              <div
                className="-textarea"
                style={{ visibility: msgSent ? 'hidden' : 'visible' }}
              >
                <p
                  ref={foo}
                  id="foo"
                  contentEditable={true}
                  onFocus={(e) => {
                    // fooPlaceholder.current.style.display = 'none'
                    !e.target.innerText.length
                      ? (e.target.innerText = ' ')
                      : null
                  }}
                  onInput={(e) => {
                    console.log(e.target.innerText.length)
                    e.target.innerText.length
                      ? (fooPlaceholder.current.style.display = 'none')
                      : (fooPlaceholder.current.style.display = 'block')
                  }}
                ></p>
                <p
                  ref={fooPlaceholder}
                  className="placeholder"
                  onClick={() => foo.current.focus()}
                >
                  Send <span className="recipient">{focusHelp[1]}</span> a
                  message?
                </p>
                <div
                  className="msg-out ico-interactive ui-handle y ps-top"
                  onClick={() => {
                    foo.current.innerText = ''
                    fooPlaceholder.current.style.display = 'block'
                    _msgSent(true)
                  }}
                >
                  <_msg_ arg="outgoing" />
                </div>
              </div>
              <div className="tools">
                {pick_me_up(
                  focusHelp[0],
                  'help',
                  __MY_SQUAD__.current.help[focusHelp[0]].users_arr.includes(
                    user_id
                  )
                )}
                <div
                  className="-X ico-interactive ui-handle"
                  onClick={() => _focusHelp([])}
                >
                  <X />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {resigned.length > 0 && (
        <div id="-resigned-" className="squad-activity-section">
          <div className="-f-">
            <div className="header">
              <ArrowLeft type="squad_activity" />
            </div>
            <div className="users-arr">{resigned}</div>
          </div>
        </div>
      )}
    </div>
  )
}
