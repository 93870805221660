import React from 'react'

export default function ArrowUp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.5 17.5" fill="none">
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M5.8,0.8v16"
      />
      <path
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.7,5.7c0,0-3.7-5-5-5s-5,5-5,5"
      />
    </svg>
  )
}
