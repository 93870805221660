import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import ROUTERJS from './components/ROUTERJS'

// import Dev from './dev/Dev.js' // keep off because of conflictions with Dev.sass

import './global.css'
import './index.css'
import './App.css'
import './beta/beta.css'

const dev = false

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  dev ? <Dev /> : <ROUTERJS />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
