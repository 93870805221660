import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleKV } from '../actions.js'
import { src } from '../.core.js'
import { desk, mob } from './ROUTERJS.js'
import _home_ from '../svg/Home.js'
import '../logo.css'

function Logo({ props: { route, _route } }) {
  const { gChatRooms, gEntry, gFocus, gJournal, gLocation } = useSelector(
    (state) => ({
      gChatRooms: state.chatRooms,
      gEntry: state.entry,
      gFocus: state.focus,
      gJournal: state.journal,
      gLocation: state.location,
    })
  )
  const dispatch = useDispatch()

  const shouldDisplay = () => {
    if (
      mob &&
      (['Home', 'Community'].includes(route) ||
        gChatRooms ||
        gEntry ||
        gFocus ||
        gJournal)
    ) {
      return 'none'
    } else {
      return 'block'
    }
  }

  function unset(params) {
    dispatch(handleKV({ entry: null, focus: null, journal: null }))
  }

  // DEV
  const $$logoVar$$ = 'img'
  // DEV

  return (
    <>
      {$$logoVar$$ === 'svg' && (
        <svg
          id="logo-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 631 631"
        >
          <path
            d="M315.5,0L315.5,0C141.254,0,0,141.254,0,315.5v0.001c0,152.617,108.366,279.92,252.348,309.173v-240.68l-82.546,82.558
		L91.95,387.998l223.55-223.55l223.55,223.55l-77.84,78.553l-82.546-82.546v240.665C522.64,595.413,631,468.113,631,315.5V315.5
		C631,141.254,489.746,0,315.5,0z"
          />
        </svg>
      )}
      {$$logoVar$$ === 'img' && (
        <div
          id="-logo"
          style={{
            display: shouldDisplay(),
          }}
          onClick={() => unset() + _route('Home')}
        >
          <img id="logo-negative" src={src('logo-negative.svg')} alt="" />
        </div>
      )}
    </>
  )
}

export default Logo
