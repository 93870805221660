import React, { useEffect, useState, useRef } from 'react'
import { src } from '../.core.js'
import { fun } from '../.fun.js'
import { mob } from '../components/ROUTERJS.js'
import { user_img } from '../components/ROUTERJS.js'

export default function Ladder({
  props: { __SQUAD__, user_rip, triggerID, setTriggerID, pathway },
}) {
  const [ladder, setLadder] = useState(user_rip.triggers.max)

  const [chronology, setChronology] = useState(false)
  // const [timeframe, setTimeframe] = useState(true) // toggle All Time / 6 Months

  const [init, setInit] = useState(false)

  const inception = __SQUAD__.current.meta.inception

  const log1 = (a, b, c) => fun().log1(a, b, c)
  const log2 = (a, b) => fun().log2(a, b)

  function get_max(int) {
    switch (true) {
      case int === 7:
        return '1w'
      case int < 14:
        return int + 'd'
      case int >= 14 && int < 30:
        return Math.floor(int / 7) + 'w'
      case int >= 30:
        return Math.floor(int / 30) + 'm'
      default:
        break
    }
  }

  function get_triggers() {
    let position = {}
    let export_triggers = []
    const time_pseudo = (e) => {
      const ISO = Array.isArray(e) ? e[0] : e
      const days = Array.isArray(e) ? e[1] : null
      const dur = days ? days : fun().msDiff(ISO, inception, 'days')
      const t = fun().niceDate(ISO, 'D-M')
      return <p className="time pseudo">{get_max(dur) + (t ? ' ' + t : '')}</p>
    }
    const element = (key, e, idx) =>
      idx > 0 ? (
        <div
          className={'appendum pseudo-top ' + key}
          onClick={() => setTriggerID(key)}
        >
          {e !== null ? time_pseudo(e) : null}
        </div>
      ) : (
        <div
          className={'-trigger pseudo-top ' + [idx === 0 ? 'first-e' : '']}
          onClick={() => setTriggerID(key)}
        >
          <p className="trigger">{key}</p>
          {e !== null ? time_pseudo(e) : null}
        </div>
      )
    const trigger_solo = (bottom, triggers_obj, idx) => (
      <div
        className="-bottom"
        style={{
          bottom: bottom + '%',
          left: 'calc(50% + 1.25rem)',
          // right: mob ? 'calc(50% + 1.25rem)' : 'initial',
          zIndex: Object.keys(position).length - idx,
        }}
      >
        {Object.entries(triggers_obj).map(([key, val], idx2) => (
          <div
            className={'-triggers ' + key}
            style={{ marginLeft: idx2 > 0 ? '1rem' : 0 }}
          >
            {val.map((e, idx3) =>
              element(key, e, idx2, idx3 === val.length - 1)
            )}
          </div>
        ))}
      </div>
    )
    // 🡇🡇 prepare data 🡇🡇
    Object.entries(user_rip.triggers).forEach(([key, val]) => {
      val.forEach(([ISO, dur]) => {
        const bottom = Math.round((dur / ladder) * 100)
        if (!(bottom in position)) {
          position[bottom] = { [key]: [] }
        }
        position[bottom][key].push([ISO, dur])
      })
    })
    // 🡅🡅 prepare data 🡅🡅
    // console.log('position --> ')
    // console.log(position)
    Object.entries(position).forEach(([bottom, triggers_obj], index) => {
      export_triggers.push(trigger_solo(bottom, triggers_obj, index))
    })
    return export_triggers
  }

  function innards(a) {
    return (
      <>
        {'›'}
        <span className="rlink inline">{a}</span>
      </>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setInit(true)
    }, 1500)
  }, [])

  return triggerID ? null : (
    <div id="-LADDER-">
      <div
        id="container"
        style={{ height: mob ? `${window.innerHeight}px` : '100%' }}
      >
        <div id="ctr-1">
          {/* <div id="-options">
            <div id="sort" onClick={() => setChronology(!chronology)}>
              <p>chronology:</p>
              <p>{chronology ? 'true' : 'false'}</p>
            </div>
          </div> */}
          <div className={'-global-user-img' + [init ? '' : ' animate']}>
            {user_img}
          </div>
          <div id="-ladder">
            <div className={'ladder x' + [init ? '' : ' animate']}></div>
            {init ? get_triggers() : null}
          </div>
        </div>
      </div>
    </div>
  )
}
