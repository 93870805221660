import React, { useEffect, useRef, useState } from 'react'

import { src, community_name } from '../.core'
import { fun } from '../.fun'

import { desk, mob, HIDE_NOFAP } from './ROUTERJS'

import lib from '../data/lib.json'

import CreateCommunity from '../components-sub/CreateCommunity'

import _launch_ from '../svg/Launch'
import _squad_ from '../svg/Squad'
import _tick_ from '../svg/Tick'
import X from '../svg/X'

import _beginner from '../svg/modifiers/Beginner'

const tags = [
  'Fitness',
  'NoFap',
  'Sobriety',
  'Elimination',
  'Learning',
  'Starter',
  'Pro',
  'Diet',
  '7 Day Challenges',
]

const search_communities = Object.keys(lib.communities)
const search_tags = Object.keys(lib.tags)

export default function Home({
  props: { user, squad_bundle, setCommunity, setSquad, setRoute },
}) {
  const searchRef = useRef()

  const randCols = useRef({
    1: fun().randCol(),
    2: fun().randCol(),
    3: fun().randCol(),
  })

  const [searching, setSearching] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [bigSearch, setBigSearch] = useState(false)

  const [create_community, _create_community] = useState(false)

  const [randCol, _randCol] = useState([])

  // My Communities
  const [hovered, setHovered] = useState(null)

  // const section_explore = (
  //   <div id="section-explore" className="g-expore">
  //     <div
  //       className={'posrel ' + [mob ? 'dummy-gradient-half-L' : 'dummy-block']}
  //     >
  //       <p className="xy ph --small">7 day challenges</p>
  //     </div>
  //     <div
  //       className={'posrel ' + [mob ? 'dummy-gradient-half-R' : 'dummy-block']}
  //     >
  //       <p className="xy ph --small">1-off challenges</p>
  //     </div>
  //   </div>
  // )

  function search(e, action = false) {
    let results_all = []
    const input = action === 'tag' ? e : e.target.value.toLowerCase()
    const without_spaces = input.replace(/\s+/g, '')
    const substr_match = (item) => item.includes(without_spaces)
    // search by input
    if (action !== 'tag' && input.length >= 3) {
      setSearching(true)
      const results_communities = search_communities.filter(substr_match)
      if (results_communities.length > 0) {
        results_all = results_communities
      }
    }
    // search by tag
    if (action === 'tag' || input.length >= 3) {
      const results_tags = search_tags.filter(substr_match)
      if (results_tags.length > 0) {
        const communities_by_tag = []
        results_tags.forEach((e) => {
          lib.tags[e].forEach((f) => {
            if (!results_all.includes(f) && !communities_by_tag.includes(f)) {
              communities_by_tag.push(f)
            }
          })
        })
        results_all = results_all.concat(communities_by_tag)
      }
      //
      if (results_all.length > 0) {
        setSearchResults(results_all)
      } else {
        setSearchResults([])
      }
    }
    if (action !== 'tag' && input < 3) {
      setSearching(false)
    }
    console.log(results_all)
  }

  function get_tags() {
    const export_tags = []
    const tag = (e) => (
      <p
        className={desk ? 'hover-low' : 'noselect'}
        onClick={() => search(e.toLowerCase(), 'tag') + setBigSearch(e)}
      >
        {e}
      </p>
    )
    tags.forEach((e) => {
      if (e === 'NoFap' && !HIDE_NOFAP) {
        export_tags.push(tag(e))
      }
    })
    return export_tags
  }

  function get_communities() {
    const export_communities = []
    const get_community = ($community, community_obj) => {
      // const isBeginner = community_obj.behaviours.includes('beginner')
      const isJoined =
        $community in user.communities &&
        !user.communities[$community].includes('ex:')
      return (
        <div
          className="-f-a community"
          onClick={() => setRoute('Community') + setCommunity($community)}
        >
          <img
            id={$community.split('.')[1]}
            src={src(`community-icons/${community_obj.img}`)}
            alt=""
          />
          <div className="inline-1">
            <p className="t rlink">
              {community_name(community_obj.name)}
              {isJoined ? (
                <div className="-f-a" style={{ display: 'inline-flex' }}>
                  {/* {isBeginner ? (
                    <div className="-Beginner inline ps-top-mini">
                      <_beginner />
                    </div>
                  ) : null} */}
                  <div className="-Tick inline ps-top-mini">
                    <_tick_ type="blocky" />
                  </div>
                </div>
              ) : null}
            </p>
            <p className="sub dim">{community_obj.sub}</p>
          </div>
        </div>
      )
    }
    searchResults.forEach(($community) => {
      const community_obj = lib.communities[$community]
      export_communities.push(get_community($community, community_obj))
    })
    return <div className="communities">{export_communities}</div>
  }

  function get_my_communities() {
    const export_communities = []
    const stats = (squad_obj) => (
      <span className="stat-current ico push-half">
        <_squad_ /> {squad_obj.stat_current}
      </span>
    )
    const startingIn = (squad_obj) => {
      let inception = squad_obj.meta.inception
      const startingInMs = fun().msDiff(inception)
      if (startingInMs.DD < 1 && startingInMs.HH < 1) {
        return (
          <>
            <span>{fun().pad(startingInMs.MM)}</span>:
            <span>{fun().pad(startingInMs.MM)}</span>
          </>
        )
      } else if (startingInMs.DD < 1 && startingInMs.HH <= 24) {
        return (
          <span>
            {startingInMs.HH + 1}
            {' hours'}
          </span>
        )
      } else {
        return <span>{'<' + fun().agoIn(inception, null, 'days')}</span>
      }
    }
    const get_community = (
      community_id,
      community_obj,
      squad_obj,
      isStartingSoon
    ) => {
      return (
        <div
          className="-f-a community --my hover-low"
          // onMouseEnter={() => {
          //   setHovered(squad_obj.squad)
          // }}
          // onMouseLeave={() => {
          //   setHovered(null)
          // }}
          onClick={() =>
            setRoute('App') +
            setCommunity(community_id) +
            setSquad(squad_obj.squad)
          }
        >
          <img
            id={community_id.split('.')[1]}
            src={src(`community-icons/${community_obj.img}`)}
            alt=""
          />
          <p className="t">
            <span>{community_name(community_obj.name, 'short')}</span>
            {isStartingSoon ? (
              <span
                // ref={(ref) => (startingInRefs.current[index] = ref)}
                className="starting-soon inline color"
              >
                <span className="inline-block push-half">
                  <_launch_ />
                </span>
                {startingIn(squad_obj)}
              </span>
            ) : (
              community_obj.counter && (
                <span className="day-x smaller">
                  {' - Day '}
                  {fun().msDiff(squad_obj.meta.inception, 'days')}
                </span>
              )
            )}
          </p>
          <div className="retainer"></div>
          <p className="sub dim">
            {hovered === squad_obj.squad || isStartingSoon
              ? community_obj.sub
              : stats(squad_obj)}
          </p>
        </div>
      )
    }
    const sorted_communities = Object.keys(squad_bundle).sort()
    sorted_communities.forEach((e) => {
      const community_obj = lib.communities[e]
      const squad_obj = squad_bundle[e]
      const isStartingSoon = squad_obj.meta.status === 'starting soon'
      // isStartingSoon && startingIn(squad_obj)
      export_communities.push(
        get_community(e, community_obj, squad_obj, isStartingSoon)
      )
    })
    return (
      <div id="section-communities" className={desk ? 'grid-gap-bottom' : ''}>
        <p className="header">My Communities</p>
        {/* <p
          id="create-community"
          className="button hover-low noselect"
          onClick={() => _create_community(true)}
        >
          + Create
        </p> */}
        <div className="communities">{export_communities}</div>
      </div>
    )
  }

  function get_results(params) {
    const results = []
    searchResults.forEach((result) => {
      const community_obj = lib.communities[result]
      results.push(
        <p onClick={() => setRoute('Community') + setCommunity(result)}>
          {community_name(community_obj.name)}
        </p>
      )
    })
    return results
  }

  function get_dummy(num) {
    return Array.from({ length: num }, (_) => null).map((e) => (
      <div className="dummy"></div>
    ))
  }

  // 🔍 Search

  return (
    <div id="-HOME-">
      {/* 🔍 Search */}
      {create_community ? (
        <CreateCommunity props={{ _create_community: _create_community }} />
      ) : (
        <>
          <div className="-search">
            <input
              ref={searchRef}
              className="search"
              type="text"
              placeholder="Search"
              onChange={(e) => search(e)}
            />
            <div className="-svg-Search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.5 17.5L22 22"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div
              className="-svg-X"
              onClick={() => {
                searchRef.current.value = ''
                searchRef.current.focus()
              }}
            >
              <X />
            </div>
            {searching ? (
              <div className="search-results">
                {searchResults.length > 0 ? (
                  get_results()
                ) : (
                  <p className="no-results dim smallest nomarj">¯\_(ツ)_/¯</p>
                )}
              </div>
            ) : null}
          </div>
          <div id="section-tags" className="-f-">
            {get_tags()}
          </div>
          {bigSearch ? (
            <div id="bigSearch">
              <p
                className="tag low-light"
                onClick={() => setBigSearch(false) + setSearchResults([])}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 81.465 81.465"
                >
                  <path
                    d="M54.872,40.743l23.66-23.66c3.91-3.91,3.91-10.24,0-14.14s-10.24-3.9-14.14,0l-23.66,23.66l-23.66-23.67
	c-3.91-3.91-10.24-3.91-14.14,0s-3.9,10.24,0,14.14l23.66,23.66l-23.66,23.66c-3.91,3.91-3.91,10.24,0,14.14s10.24,3.9,14.14,0
	l23.66-23.66l23.66,23.66c3.91,3.91,10.24,3.91,14.14,0s3.9-10.24,0-14.14l-23.66-23.66V40.743z"
                  />
                </svg>
                {bigSearch}
              </p>
              {get_communities()}
            </div>
          ) : (
            <div className="grid">
              <div
                id="News-Announcements"
                className="posrel g-l1"
                style={{ background: randCols.current[1] }}
              >
                <p className="xy ph black">News & Announcements</p>
              </div>
              <div
                id="Upcoming-Month"
                className="posrel g-l2"
                style={{ background: randCols.current[2] }}
              >
                <p className="xy ph black">Upcoming Month</p>
              </div>
              <div
                id="Communities-Spotlight"
                className="posrel g-l3"
                style={{ background: randCols.current[3] }}
              >
                <p className="xy ph black">Communities Spotlight</p>
              </div>
              <div id="My-Communities" className="column-1fr g-communities">
                {get_my_communities()}
              </div>
            </div>
          )}
          <div id="-footer">
            <div id="footer">
              <p>About</p>
              <p>Contact</p>
              {get_dummy(2)}
              {get_dummy(3)}
              <p>© foo 2024</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
