import React, { useEffect, useState } from 'react'
import './developer.css'

import { mob, DJANGO } from '../components/ROUTERJS'

import { fun } from '../.fun'

export default function Developer({
  props: { AboutPrn, setAboutPrn, setDev },
}) {
  const [open, setOpen] = useState(false)

  function __squad(path, queries = null) {
    if (DJANGO) {
      const url = queries ? `__squad/${path}?${queries}` : `__squad/${path}`
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((json_data) => {
          console.log(json_data)
        })
        .catch((error) => {
          console.error('Fetch error:', error)
        })
    } else {
      alert('You have no backend!')
    }
  }

  useEffect(() => {
    // const now = new Date()
    // const date1 = fun().dateAgoIn('1 hours')
    // // const date2 = fun().dateAgoIn('-1 hours')
    // // const date3 = fun().dateAgoIn('3 days')
    // // const date4 = fun().dateAgoIn('-3 days')
    // console.log(now)
    // console.log(date1)
    // // console.log(date2)
    // // console.log(date3)
    // // console.log(date4)
  }, [])

  // NOTES
  // className="function unavailable" <- temporarily disable function

  return (
    <div
      id="developer-window"
      className={open && 'open'}
      onMouseEnter={!mob ? () => setOpen(true) : null}
      onMouseLeave={!mob ? () => setOpen(false) : null}
      onClick={mob ? () => setOpen(true) : null}
      style={{ height: mob ? window.innerHeight + 'px' : '66vh' }}
    >
      <p
        id="toggle-Dev"
        onClick={() => setDev(false)}
        style={{ color: 'indianred' }}
      >
        disable
      </p>
      <p
        id="X-mob"
        className="m0"
        onClick={(e) => e.stopPropagation() + setOpen(false)}
      >
        x
      </p>
      <div id="contents">
        <p className="header">{'< Developer Window >'}</p>
        <p id="first-child" className="dim">
          functions
        </p>
        <div id="functions">
          <p className="function" onClick={() => fun().test()}>
            fun().test()
          </p>
        </div>
        <p className="dim">works in progress</p>
        <div id="worksinprogress" className="grid">
          <p className="style">About P*rn</p>
          <p className="bool" onClick={() => setAboutPrn(!AboutPrn)}>
            {AboutPrn ? 'show' : 'hide'}
          </p>
        </div>
        {/* <p className="dim">alternate styles</p>
        <div id="styles" className="grid"></div> */}
      </div>
    </div>
  )
}
