import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { NA } from '../.core.js'
import { fun } from '../.fun.js'
import { desk, mob, user_img } from './ROUTERJS'

import _arrowReturn_ from '../svg/ArrowReturn.js'
import _msgDM_ from '../svg/MsgDM.js'
import X from '../svg/X.js'

export default function Profile({
  props: {
    community_id,
    profile_open,
    route,
    showFarewell,
    user,
    _showFarewell,
    _profile_open,
  },
}) {
  const { gChatRooms, gEntry, gFocus, gJournal, gLocation } = useSelector(
    (state) => ({
      gChatRooms: state.chatRooms,
      gEntry: state.entry,
      gFocus: state.focus,
      gJournal: state.journal,
      gLocation: state.location,
    })
  )

  const [showFarewellPrompt, _showFarewellPrompt] = useState(false)

  const tools = {
    Relapse_Report: {
      Component: <_arrowReturn_ />,
      display: showFarewellPrompt,
      isActive: showFarewell,
      fn: () => _showFarewell(true),
    },
    DMs: {
      Component: <_msgDM_ />,
      display: true,
      isActive: false,
      fn: () => alert(NA),
    },
  }

  const shouldDisplay = () => {
    if (
      mob &&
      (route === 'Community' || gChatRooms || gEntry || gFocus || gJournal)
    ) {
      return 'none'
    } else {
      return 'block'
    }
  }

  function get_tools() {
    const tool = (key, obj) => (
      <div
        id={'tool-' + key}
        className={
          'click-me ui-handle -pseudo-bottom' + (obj.isActive ? ' active' : '')
        }
        onClick={() => obj.fn()}
      >
        {obj.Component}
        {key !== 'DMs' && <p className="pseudo">{key.replace('_', ' ')}</p>}
      </div>
    )
    return (
      <div id="right-side-tools" className="top-tools">
        {Object.entries(tools).map(([key, val]) => {
          if (val.display) {
            return tool(key, val)
          }
        })}
      </div>
    )
  }

  function unset() {
    setOpen(false)
    _showFarewell(false)
  }

  useEffect(() => {
    if (route === 'App') {
      const user_comm_data = user.communities_data[community_id]
      if (fun().msDiff([user_comm_data.joined], 'hours') < 24) {
        _showFarewellPrompt(true)
      }
    }
  }, [community_id, user])

  return (
    <>
      {/* {mob && drop()} */}
      <div
        id="Profile"
        className="clickDrop"
        style={{
          display: shouldDisplay(),
        }}
      >
        <div onClick={() => _profile_open(true)}>{user_img}</div>
        {desk && get_tools()}
      </div>
    </>
  )
}
