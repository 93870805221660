import React, { useEffect, useState, useRef } from 'react'

import { fun } from '../.fun.js'
import { community_name, src } from '../.core.js'

import { desk, mob } from './ROUTERJS.js'

import lib from '../data/lib.json'

import Rules from '../components-sub/Rules.js'

import ArrowBack from '../svg/ArrowBack.js'
import _globe_ from '../svg/Globe.js'
import _reset_ from '../svg/Reset.js'

// > temp
import users from '../data/REACT_APP.users.json'
// < temp

export default function Community({
  props: {
    __MY_SQUAD__,
    community_id,
    _showPopUp,
    leave_squad,
    pathway,
    RESET,
  },
}) {
  const [confirmRESET, _confirmRESET] = useState(false)

  const [mouseInception, setMouseInception] = useState(false)

  const [nicknamePrompt, _nicknamePrompt] = useState(false)
  const [nicknameConfirmSuggestion, _nicknameConfirmSuggestion] =
    useState(false)
  const [inputVal, _inputVal] = useState('')
  const [poll, _poll] = useState(null)

  // Dev
  const $$isTOMORROWSquad$$ = false
  // Dev

  const isTOMORROWSquad =
    __MY_SQUAD__.current.meta.status !== true || $$isTOMORROWSquad$$

  function do_submit() {
    const new_entry = { [inputVal]: 1 }
    if (poll) {
      const copy_poll = { ...poll }
      copy_poll[inputVal] = 1
      console.log(copy_poll)
      _poll(copy_poll)
    } else {
      _poll(new_entry)
    }
    _nicknameConfirmSuggestion(false)
    _nicknamePrompt(false)
  }

  function aroundtheworld(params) {
    let flags = []
    // let continents_arr = []
    // const get_continent = (flag) => {
    //   for (const [key, val] of Object.entries(continents)) {
    //     if (val.includes(flag)) {
    //       return key
    //     }
    //   }
    //   return 'Unknown'
    // }
    __MY_SQUAD__.current.users.forEach(($user) => {
      const flag = users[$user].flag
      if (flag && !flags.includes(flag)) {
        flags.push(flag)
      }
      // const continent = get_continent(flag)
      // if (continent !== 'Unknown' && !continents_arr.includes(continent)) {
      //   continents_arr.push(continent)
      // }
    })
    return (
      <div className="-f-a">
        <_globe_ type="line" />
        <p className="inline-1">
          {__MY_SQUAD__.current.stat_current} members from {flags.length}{' '}
          countries.
          {/* spanning {continents_arr.length} continents. */}
        </p>
      </div>
    )
  }

  useEffect(() => {
    if (__MY_SQUAD__.current.meta.nickname?.poll) {
      _poll(__MY_SQUAD__.current.meta.nickname.poll)
    }
    return () => {}
  }, [])

  return (
    <div id="COMMUNITY">
      <div className={'content ' + (mob ? 'clear-navbar-1' : 'clear-toolbars')}>
        {nicknamePrompt ? (
          <>
            <div id="toolbar">
              <div className="click-me-arrowBack">
                <ArrowBack pathway={() => _nicknamePrompt(false)} />
              </div>
            </div>
            <div id="-suggest">
              {nicknameConfirmSuggestion ? (
                <>
                  <p>Nicknames are limited to 1 suggestion per user.</p>
                  <p>
                    A nickname can't be altered or changed after 1 person has
                    voted for it.
                  </p>
                  <p id="propose">
                    Propose nickname:{' '}
                    <span className="nickname">{inputVal}</span> ?
                  </p>
                  <p className="clink" onClick={() => do_submit()}>
                    confirm
                  </p>
                </>
              ) : (
                <>
                  <p>Squads can be given a unique nickname.</p>
                  <p>
                    A successful name has to achieve a majority vote of at least
                    a one third of the current squad size.
                  </p>
                  <div id="suggest" className="-f-a">
                    <p>Suggest a name:</p>
                    <div className="-input inline">
                      <input
                        type="text"
                        placeholder="..."
                        spellCheck="false"
                        onChange={(e) => _inputVal(e.target.value)}
                      />
                      <p className="after">
                        between 6 - 20 characters.
                        <span
                          className="inline"
                          style={{
                            display:
                              inputVal.length > 0 ? 'inline-block' : 'none',
                          }}
                        >
                          len:{' '}
                          <span
                            className={
                              inputVal.length > 20
                                ? 'over'
                                : inputVal.length < 6
                                ? 'under'
                                : ''
                            }
                          >
                            {inputVal.length}
                          </span>
                        </span>
                      </p>
                    </div>
                    <p
                      className="clink inline"
                      onClick={() => _nicknameConfirmSuggestion(true)}
                      style={{
                        display:
                          inputVal.length >= 6 && inputVal.length <= 20
                            ? 'block'
                            : 'none',
                      }}
                    >
                      submit
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {confirmRESET && (
              <div className="-m-">
                <div className="--f-a pop-up">
                  <p id="confirmRESET">Confirm Restart</p>
                  <div className="-f-">
                    <p className="hover-low" onClick={() => RESET()}>
                      Yes
                    </p>
                    <p
                      className="hover-low inline-2"
                      onClick={() => _confirmRESET(false)}
                    >
                      No
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div id="content-header">
              {desk && (
                <img
                  id="community-img"
                  src={src(
                    'community-icons/' + lib.communities[community_id].img
                  )}
                  alt=""
                />
              )}
              <p id="community_name">
                {community_name(lib.communities[community_id].name, 'short')}
              </p>
              <div id="actions">
                <div
                  id="reset"
                  className={
                    'ps-bottom ' + (isTOMORROWSquad ? 'isTOMORROWSquad' : '')
                  }
                  onClick={() => !isTOMORROWSquad && _confirmRESET(true)}
                >
                  <_reset_ />
                </div>
                <div
                  id="leave"
                  onClick={() => leave_squad(__MY_SQUAD__.current.squad)}
                >
                  <p>Leave Community</p>
                </div>
              </div>
            </div>
            {isTOMORROWSquad ? (
              <p id="isTOMORROWSquad">
                Your squad hasn't launched yet.
                <sup
                  className="i"
                  onClick={() =>
                    _showPopUp({
                      id: 'RESET',
                      payload: {
                        __MY_SQUAD__: __MY_SQUAD__,
                      },
                    })
                  }
                >
                  ?
                </sup>
              </p>
            ) : (
              <>
                <div className="section">
                  <p id="squad_id">
                    {'My Squad: '}
                    <span>#{__MY_SQUAD__.current.squad}</span>
                  </p>
                  <p id="inception" className="dim">
                    Inception:
                    <span
                      id="inception"
                      className="pseudo-top posrel inline"
                      onMouseEnter={() => setMouseInception(true)}
                      onMouseLeave={() => setMouseInception(false)}
                    >
                      <span
                        className="dim"
                        style={{
                          visibility: mouseInception ? 'hidden' : 'visible',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {fun().niceDate(
                          __MY_SQUAD__.current.meta.inception,
                          'DMY'
                        )}
                      </span>
                      <span
                        className="x0y0 dim"
                        style={{
                          display: mouseInception ? 'block' : 'none',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {fun().agoIn(__MY_SQUAD__.current.meta.inception)}
                      </span>
                      {/* <span className="pseudo italic color">
                    "Don't count the days, make the days count."
                  </span> */}
                    </span>
                  </p>
                  {poll ? (
                    <div className="-poll">
                      <p className="mtb0">
                        Vote on a squad nickname?{' '}
                        <span
                          className="learn-more"
                          onClick={() => _nicknamePrompt(true)}
                        >
                          Learn more
                        </span>
                      </p>
                      <p
                        className="inline side-note dim"
                        style={{ marginTop: '.5em' }}
                      >
                        Votes to win:{' '}
                        {Math.round(__MY_SQUAD__.current.stat_current / 3)}
                      </p>
                      <div className="-suggestions">
                        {Object.entries(poll).map(([key, val]) => (
                          <>
                            <p className="suggestion">{key}</p>
                            <p>{val}</p>
                            <p className="vote" onClick={() => alert('vote')}>
                              vote
                            </p>
                          </>
                        ))}
                      </div>
                      <p className="side-note dim mb0">
                        You can cast as many votes as you like (1 per nickname)
                      </p>
                    </div>
                  ) : __MY_SQUAD__.current.meta.nickname?.name ? (
                    <p>{__MY_SQUAD__.current.meta.nickname.name}</p>
                  ) : (
                    <p
                      className="dim rlink fit-content"
                      onClick={() => _nicknamePrompt(true)}
                    >
                      Give nickname?
                    </p>
                  )}
                </div>
                <div id="-stats-" className="section">
                  <p className="label">
                    {'Current: '}
                    <span id="stat-current">
                      {__MY_SQUAD__.current.stat_current}
                    </span>
                  </p>
                  <p className="label">
                    {'Initial: '}
                    <span
                      id="stat-initial"
                      className={
                        !__MY_SQUAD__.current.meta.initial ? 'dim' : ''
                      }
                    >
                      {String(__MY_SQUAD__.current.meta.initial)}
                    </span>
                  </p>
                  <p className="label">
                    {'Resigned: '}
                    <span id="stat-resigned">
                      {__MY_SQUAD__.current.meta.initial -
                        __MY_SQUAD__.current.stat_current}
                    </span>
                  </p>
                </div>
                <div id="around-the-world" className="section foo-section">
                  {aroundtheworld()}
                </div>
                <Rules community_id={community_id} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
