import React, { Component, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleKV } from '../actions'
import { community_name, src } from '../.core.js'
import { fun } from '../.fun.js'
import { desk, mob } from './ROUTERJS'
import lib from '../data/lib.json'

import ArrowBack from '../svg/ArrowBack.js'
import COG from '../svg/COG.js'
import _feed_ from '../svg/Feed.js'
import _home from '../svg/Home.js'
import _journal from '../svg/Journal.js'
import Menu from '../svg/Menu.js'
import Plus from '../svg/Plus.js'
import _squad from '../svg/Squad.js'
import _status from '../svg/Status.js'
import _trigger from '../svg/Trigger.js'
import X from '../svg/X.js'

import Elimination from '../svg/modifiers/Elimination.js'
import Ranked from '../svg/modifiers/Ranked.js'

import '../logo.css'

function LogoTools({
  props: {
    community_id = null,
    createEntry = null,
    g,
    location = null,
    overlay = null,
    route = null,
    showPopUp = null,
    userPathway,
    _community_id = null,
    _createEntry = null,
    _location = null,
    _route = null,
    _showPopUp = null,
    handleStatus,
    switchSquad = null,
    pathway = null,
    unset = null,
  },
}) {
  const { gChatRooms, gEntry, gFocus, gJournal } = useSelector((state) => ({
    gChatRooms: state.chatRooms,
    gEntry: state.entry,
    gFocus: state.focus,
    gJournal: state.journal,
  }))

  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const thisCommunity = lib.communities[community_id]

  const common_links = ['Feed']

  const nav_links = {
    pro: {
      Community: (
        <img src={src('community-icons/' + thisCommunity.img)} alt="" />
      ),
      My_Squad: <Elimination />,
      Global_Squad: <Ranked />,
    },
    reg: {
      Feed: <_feed_ />,
      // Triggers: <_trigger />,
      // Resources: <_resources />,
    },
  }

  const tools = {
    Add_Status: {
      Component: <_status />,
      isActive: showPopUp === 'add_activity',
      click: () =>
        _showPopUp({
          id: 'add_activity',
          payload: {
            handleStatus: handleStatus,
          },
        }),
    },
    Add_Journal: {
      Component: <_journal />,
      isActive: createEntry,
      click: () => _createEntry('journal'),
    },
  }

  const special_onClick = {
    Community: () =>
      dispatch(handleKV('LOCATION, community')) + _location('community'),
    My_Squad: () => switchSquad('my_squad'),
    Global_Squad: () => switchSquad('global_squad'),
  }

  // const handleMobCommunityBack = () => {
  //   // dispatch(resetLOCATION())
  //   _location('feed')
  //   setOpen(true)
  // }

  function get_mob_nav() {
    const menu_item = (key, Component) => (
      <div
        id={'menu_item_' + key.replace(' ', '_').toLowerCase()}
        className={'-f-a' + (location !== key.toLowerCase() ? ' not' : null)}
        onClick={() => {
          LogoToolsUnset()
          special_onClick[key]?.() ?? _location(key.toLowerCase())
        }}
      >
        <div className="-icon">{Component}</div>
        <p>
          {key === 'Community'
            ? community_name(thisCommunity.name, 'short')
            : key.replace('_', ' ')}
        </p>
      </div>
    )
    return (
      <>
        <div id="navigation" className="main-menu background">
          <div className="-X ui-element" onClick={() => setOpen(false)}>
            <X />
          </div>
          <div className="-f-ja -squad-options">
            <div
              className={
                '--f-a -mod squad' +
                (location !== 'my_squad' ? ' inactive' : '')
              }
              onClick={() =>
                dispatch(handleKV('LOCATION, my_squad')) +
                _location('my_squad') +
                switchSquad('my_squad') +
                setOpen(false)
              }
            >
              <Elimination />
              <p>My Squad</p>
            </div>
            <div
              className={
                '--f-a -mod global' +
                (location !== 'global_squad' ? ' inactive' : '')
              }
              onClick={() =>
                dispatch(handleKV('LOCATION, global_squad')) +
                _location('global_squad') +
                switchSquad('global_squad') +
                setOpen(false)
              }
            >
              <Ranked />
              <p>Global Squad</p>
            </div>
          </div>
          <div className="separator"></div>
          <div className="menu-items-wrapper">
            {menu_item('Community', (Component = nav_links.pro.Community))}
            {menu_item('Feed', (Component = nav_links.reg['Feed']))}
          </div>
        </div>
      </>
    )
  }

  function get_nav() {
    let export_nav = []
    const nav = (parent, key, Component) => (
      <div
        id={'nav-' + key}
        className={
          '-f-a nav ' +
          parent +
          (location !== key.toLowerCase() ? ' not' : null)
        }
        onClick={() => {
          LogoToolsUnset()
          special_onClick[key]?.() ?? _location(key.toLowerCase())
        }}
      >
        <div id={'ico-' + key} className={'-nav-icon click-me ' + parent}>
          {Component}
        </div>
        <p>
          {key === 'Community'
            ? community_name(thisCommunity.name, 'short')
            : key.replace('_', ' ')}
        </p>
      </div>
    )
    Object.entries(nav_links.pro).forEach(([key, val]) => {
      export_nav.push(nav('pro', key, (Component = val)))
    })
    Object.entries(nav_links.reg).forEach(([key, val]) => {
      if (
        common_links.includes(key) ||
        lib.communities[community_id].nav_links?.includes(key)
      ) {
        export_nav.push(nav('reg', key, (Component = val)))
      }
    })
    return (
      <>
        <div id="navigation">{export_nav}</div>
      </>
    )
  }

  function get_tools() {
    let export_tools = []
    const tool = (key, Component, isActive, click) => (
      <div
        id={'tool-' + key}
        className={
          'squad-tool click-me ui-handle -pseudo-bottom' +
          (isActive ? ' active' : '')
        }
        onClick={() => click()}
      >
        {Component}
        <p className="pseudo">{key.replace('_', ' ')}</p>
      </div>
    )
    Object.entries(tools).forEach(([key, val]) => {
      export_tools.push(
        tool(key, (Component = val.Component), val.isActive, val.click)
      )
    })
    return (
      <div id="left-side-tools" className="top-tools">
        {export_tools}
      </div>
    )
  }

  function LogoToolsUnset() {
    setOpen(false)
    unset()
  }

  return (
    <>
      {mob && (
        <>
          {open && get_mob_nav()}
          <div id="-logo-tools" className="posrel">
            {
              userPathway.length > 0 && <ArrowBack pathway={pathway} />
              // <div className="ui-handle" onClick={() => setOpen(!open)}>
              //   <Menu />
              // </div>
            }
          </div>
          {route === 'App' ? (
            <>
              {!(gChatRooms || gEntry || gFocus || gJournal) ? ( // ['my_squad', 'global_squad', 'feed'].includes(location) &&
                <div
                  id="-squad-option-navbar"
                  className="x"
                  onClick={() => setOpen(!open)}
                >
                  {location === 'community' ? (
                    <div className="community">
                      <img
                        src={src('community-icons/' + thisCommunity.img)}
                        alt=""
                      />
                    </div>
                  ) : location === 'feed' ? (
                    <div className="feed">
                      <_feed_ />
                    </div>
                  ) : g ? (
                    <div className="-mod global">
                      <Ranked />
                    </div>
                  ) : (
                    <div className="-mod squad">
                      <Elimination />
                    </div>
                  )}
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
      {desk && (
        <div id="-logo-tools" className="posrel">
          {get_nav()}
          {route === 'App' &&
            ['my_squad', 'global_squad'].includes(location) &&
            get_tools()}
        </div>
      )}
    </>
  )
}

export default LogoTools
