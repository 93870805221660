import { DJANGO } from './components/ROUTERJS.js'

const $$play$$ = false

const play = () => {
  if ($$play$$) {
    const audio = DJANGO
      ? new Audio('/static/dev/442546-SBsfi1-Error_Simple_010.wav')
      : new Audio('/dev/442546-SBsfi1-Error_Simple_010.wav')
    audio.play()
  }
}

export function fun() {
  //      typeof
  //      parse / convert / format
  //      date
  //      random
  //      gen
  //      get
  //      manipulate
  //      log

  //      NOTES

  // ⚠️🟡❗🔻🔺🔍🗒️➡️
  // ➀➁➂➃➄➅➆➇🡇🡅⬎

  return {
    mediaType: (extension) => {
      switch (extension.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'webp':
          return ['an', 'image']
        case 'mp4':
        case 'mov':
        case 'webm':
          return ['a', 'video']
        default:
          return 'unknown'
      }
    },

    parseBool(str) {
      return /^(true|1)$/i.test(str.trim())
    },
    pad: (num) => {
      if (num < 10) {
        return '0' + num.toString()
      } else {
        return num
      }
    },
    simplifyNum: (input) => {
      const num = parseFloat(input)

      if (isNaN(num)) {
        return 'NaN'
      }

      const lessPoint1 = (num) => {
        if (num % 1 === 0) {
          return num
        } else {
          const [number, float] = num.toString().split('.')
          if (float.charAt(0) === '0') {
            return Math.floor(num)
          } else {
            return parseFloat(`${number}.${float.charAt(0)}`)
          }
        }
      }

      if (num < 1000) {
        return num.toString()
      } else if (num < 1000000) {
        let result = num / 1000
        return lessPoint1(result) + 'k'
      } else if (num < 1000000000) {
        let result = num / 1000000
        return lessPoint1(result) + 'M'
      } else {
        let result = num / 1000000000
        return lessPoint1(result) + 'B'
      }
    },
    capitalize: (str) => {
      if (!str) return str
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    },
    letterToDialPad: (letter) => {
      const dialPadMapping = {
        A: 2,
        B: 2,
        C: 2,
        D: 3,
        E: 3,
        F: 3,
        G: 4,
        H: 4,
        I: 4,
        J: 5,
        K: 5,
        L: 5,
        M: 6,
        N: 6,
        O: 6,
        P: 7,
        Q: 7,
        R: 7,
        S: 7,
        T: 8,
        U: 8,
        V: 8,
        W: 9,
        X: 9,
        Y: 9,
        Z: 9,
      }
      letter = letter.toUpperCase()
      return dialPadMapping[letter] || null
    },

    randCol: () => {
      let colors = []
      for (let i = 0; i < 4; i++) {
        colors.push(Math.floor(Math.random() * (255 - 0)) + 0)
      }
      const calib = Math.floor(255 / 2)
      if (colors[0] > calib && colors[1] > calib && colors[2] > calib) {
        const randEntry = Math.floor(Math.random() * (2 - 0)) + 0
        const newCol = Math.floor(Math.random() * calib)
        colors[randEntry] = newCol
      }
      return `rgb(${colors[0]}, ${colors[1]}, ${colors[2]})`
    },
    randFloat: (a, b) => {
      if (a !== undefined && b !== undefined) {
        console.log('randFloat() no a and b params not set up')
        return
      }
      if (b === undefined) {
        // ...
        const randomFloat = Math.random() * (a - 0.1) + 0.1
        return parseFloat(randomFloat.toFixed(1))
      }
      console.log('randFloat() not set up')
    },
    randFromArr: (arr, vol = null) => {
      if (vol === 0) {
        alert('fun().randFromArr() required 0 from arr')
        return
      } else if (vol) {
        const randSelection = []
        const arr_copy = [...arr]
        const arr_length = arr_copy.length
        const diff = arr_length - vol
        if (vol > arr_length) {
          alert(
            '%c@fun().randFromArr() vol > arr_length',
            'font-size: 1rem;color: red'
          )
          return
        }
        for (let i = arr_length; i > diff; i--) {
          const randIndex = fun().randInt(0, i - 1)
          randSelection.push(arr_copy.splice(randIndex, 1)[0])
        }
        return randSelection
      } else {
        return arr[fun().randInt(0, arr.length - 1)]
      }
    },
    randInt: (a = null, b = null) => {
      if (a === null) {
        return Math.floor(Math.random() * 10) + 1
      } else if (!a && !b) {
        return 0
      } else {
        if (b === null) {
          b = 1
        }
        const min = Math.min(a, b)
        const max = Math.max(a, b)
        return Math.floor(Math.random() * (max - min + 1)) + min
      }
    },
    shuffle(any) {
      let type
      let arr = any
      if (typeof any === 'string') {
        type = 'string'
        arr = any.split('')
      }
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[arr[i], arr[j]] = [arr[j], arr[i]]
      }
      if (type === 'string') {
        return arr.join('')
      } else {
        return arr
      }
    },

    compareDate: (iso1, iso2 = null) => {
      const date1 = iso1.split('T')[0]
      const date2 = iso2
        ? iso2.split('T')[0]
        : new Date().toISOString().split('T')[0]
      if (!iso2) {
        return date1 === date2
      }
      if (date1 === date2) {
        return 'same'
      } else if (date1 > date2) {
        return 'future'
      } else {
        return 'past'
      }
    },
    niceDate: (iso, format) => {
      const delimiters = new Set(['-', ':'])
      const date = iso ? new Date(iso) : new Date()
      const formatArr = format.split('')
      const lastIdx = formatArr.length - 1
      let str = ''
      formatArr.forEach((char, idx) => {
        switch (char) {
          case 'D':
            str += date.getDate()
            break
          case 'M':
            str += date.toLocaleDateString('en-US', { month: 'long' })
            break
          case 'm':
            str += date.toLocaleDateString('en-US', { month: 'short' })
            break
          case 'Y':
            str += date.getFullYear()
            break
          case 'y':
            str += date.getFullYear() % 100
            break
          case 'T':
            str += date.getHours().toString().padStart(2, '0')
            break
          case 't':
            str += date.getMinutes().toString().padStart(2, '0')
            break
          default:
            if (delimiters.has(char)) {
              str += char
            } else {
              console.log(
                'format includes other than D, M, m, Y, y, T, t, - or :'
              )
            }
        }
        if (!delimiters.has(char) && idx !== lastIdx) {
          if (!delimiters.has(formatArr[idx + 1])) {
            str += ' '
          }
        }
      })
      return str
    },
    agoIn: (iso, date2 = null, withRespect = null) => {
      let isFutureDate = false
      const date1 = new Date(iso)
      date2 = date2 ? new Date(date2) : new Date()
      const diff = date2 - date1
      if (diff < 0) {
        isFutureDate = true
      }
      const absDiff = Math.abs(diff)

      const seconds = Math.floor(absDiff / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days =
        withRespect === 'days' ? Math.ceil(hours / 24) : Math.floor(hours / 24)
      const weeks = Math.floor(days / 7)
      const months = Math.floor(days / 30)
      const years = Math.floor(days / 365)

      if (years > 0) {
        return years === 1
          ? isFutureDate
            ? 'a year'
            : 'a year ago'
          : isFutureDate
          ? `${years} years`
          : `${years} years ago`
      } else if (months > 0) {
        return months === 1
          ? isFutureDate
            ? 'a month'
            : 'a month ago'
          : isFutureDate
          ? `${months} months`
          : `${months} months ago`
      } else if (weeks > 0) {
        return weeks === 1
          ? isFutureDate
            ? 'a week'
            : 'a week ago'
          : isFutureDate
          ? `${weeks} weeks`
          : `${weeks} weeks ago`
      } else if (days > 0) {
        return days === 1
          ? isFutureDate
            ? 'tomorrow'
            : 'a day ago'
          : isFutureDate
          ? `${days} days`
          : `${days} days ago`
      } else if (hours > 0) {
        return hours === 1
          ? isFutureDate
            ? 'an hour'
            : 'an hour ago'
          : isFutureDate
          ? `${hours} hours`
          : `${hours} hours ago`
      } else if (minutes > 0) {
        return minutes === 1
          ? isFutureDate
            ? '1 minute'
            : 'a minute ago'
          : isFutureDate
          ? `${minutes} minutes`
          : `${minutes} minutes ago`
      } else {
        return seconds === 1
          ? isFutureDate
            ? '1 second'
            : 'a second ago'
          : isFutureDate
          ? `${seconds} seconds`
          : `${seconds} seconds ago`
      }
    },
    msDiff: (iso, type = null) => {
      const isoDate = new Date(iso)
      const currentDate = new Date()
      if (isNaN(isoDate.getTime())) {
        console.error('Invalid iso date string')
        return
      }
      const diff_ms = currentDate - isoDate
      const days = () => Math.floor(Math.abs(diff_ms / (1000 * 60 * 60 * 24)))
      const hours = () =>
        Math.floor(
          Math.abs((diff_ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        )
      const minutes = () =>
        Math.floor(Math.abs((diff_ms % (1000 * 60 * 60)) / (1000 * 60)))
      const seconds = () => Math.floor(Math.abs((diff_ms % (1000 * 60)) / 1000))
      switch (type) {
        case 'days':
          return days()
        case 'minutes':
          return minutes()
        case 'seconds':
          return seconds()
        default:
          return {
            DD: days(),
            HH: hours(),
            MM: minutes(),
            SS: seconds(),
          }
      }
    },
    dateAgoIn: (val = null) => {
      const currentDate = new Date()
      if (val) {
        const [numStr, type] = val.split(' ')
        const num = parseInt(numStr, 10)
        switch (type) {
          case 'hours':
            currentDate.setHours(currentDate.getHours() + num)
            break
          case 'days':
            currentDate.setDate(currentDate.getDate() + num)
            break
          default:
            throw new Error('Unsupported time unit. Use "hours" or "days".')
        }
      }
      return currentDate.toISOString()
    },

    lastElm: (arr) => {
      return arr[arr.length - 1]
    },

    removeFromArr: (remove, arr) => {
      const removeSet = new Set(Array.isArray(remove) ? remove : [remove])
      return arr.filter((item) => !removeSet.has(item))
    },

    logMethods: {
      blue: (a) => {
        console.log(`%c${a}`, 'color:blue;')
      },
      lightblue: (a) => {
        console.log(`%c${a}`, 'color:lightblue;')
      },
      steelblue: (a) => {
        console.log(`%c${a}`, 'color:steelblue;')
      },
      yellow: (a) => {
        console.log(`%c${a}`, 'color:yellow;')
      },
    },
    log0: (str) => {
      console.log(`✱ ${str}`)
    },
    log1: (a, c = 'white') => {
      console.log(`%c↓↓ ${a} ↓↓`, `color: ${c}; font-size: 2rem;`)
    },
    log2: (a, b, c = 'white') => {
      if (b === undefined) {
        b = a
        a = 'Oopsie!'
      }
      console.log(`%c${a} ➞ ${b}`, `color: ${c};`)
    },
    notice: (a = null) => {
      if (['red', 'green', 'blue'].includes(a)) {
        console.log('%c🡇', `color:${a};font-size:2rem`)
      } else {
        console.log('%c🡇', 'color:yellow;font-size:2rem')
        if (a) {
          console.log(a)
        }
      }
    },

    test: () => {
      play()
      fun().log0('foo')
    },
  }
}
