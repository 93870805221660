import React, { useEffect, useRef, useState } from 'react'
import { desk, mob } from '../components/ROUTERJS'
import lib from '../data/lib.json'

import _about_ from '../svg/About'

export default function Rules({ community_id }) {
  const rulesRefsDic = useRef({})
  const ruleAboutRef = useRef()
  const [mob_ps, set_mob_ps] = useState(null)

  const community_lib = lib.communities[community_id]

  function handleDocumentClick(event) {
    let clickedRule = false
    Object.entries(rulesRefsDic.current).forEach(([k, v]) => {
      if (v.contains(event.target)) {
        clickedRule = true
        set_mob_ps(k)
      }
    })
    if (!clickedRule && event.target !== ruleAboutRef.current) {
      set_mob_ps(null)
    }
  }

  function get_rules() {
    const rules = community_lib.rules
    const export_core_rules = []
    const export_rules = []
    const rule = (k, v, isCore = false) => {
      let _k = k,
        about = false
      if (isCore) {
        switch (k) {
          case 'AFK':
            _k = 'Max AFK'
            v = v + ' days'
            about = true
            break
          default:
            break
        }
      }
      return (
        <p
          ref={isCore ? (ref) => (rulesRefsDic.current[k] = ref) : null}
          className="rule"
        >
          {_k} :{' '}
          <span>
            {v}
            {about ? (
              <div className="-About inline">
                {desk ? (
                  <p className="rule-about">{lib.rules_about[k]}</p>
                ) : null}
                <div>
                  <_about_ />
                </div>
              </div>
            ) : null}
          </span>
        </p>
      )
    }
    Object.entries(rules).forEach(([key, value]) => {
      if (key === 'core') {
        Object.entries(value).forEach(([key2, value2]) => {
          export_core_rules.push(rule(key2, value2, true))
        })
      } else {
        export_rules.push(rule(key, value))
      }
    })
    return (
      <>
        {export_core_rules.length > 0 ? (
          <div className="core-rules">{export_core_rules}</div>
        ) : null}
        <div className="rules">{export_rules}</div>
      </>
    )
  }

  useEffect(() => {
    if (mob) {
      document.addEventListener('touchstart', handleDocumentClick)
    }

    return () => {
      document.removeEventListener('touchstart', handleDocumentClick)
    }
  }, [])

  return (
    <div className="-rules posrel">
      {mob && mob_ps ? (
        <p ref={ruleAboutRef} className="rule-about color">
          {lib.rules_about[mob_ps]}
        </p>
      ) : null}
      <p className="t">Rules:</p>
      {get_rules()}
    </div>
  )
}
